import { Box, Grid2, Typography, Paper, Button, CircularProgress, Breadcrumbs, Link, IconButton, Tooltip, Divider } from '@mui/material';
import React, { useState } from 'react';
import {  PricingTableColumns } from '../Product/utils';
import EditableTable from '../../components/EditableTable/EditableTable';
import { useProductDetails } from '../Product/hooks'
import { useParams, useNavigate } from 'react-router-dom';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import { Helmet } from 'react-helmet-async';
import HomeIcon from '@mui/icons-material/Home';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const ProductDetails = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const { 
      skuRows, 
      setSkuRows, 
      skuTableColumns, 
      loading, 
      skus, 
      parentProduct, 
      pricingTableRows, 
      imageList, 
      handleAddToCart, 
      setSelectedQuantities,
      currentEdit
    } = useProductDetails(slug);
    const [showShareOptions, setShowShareOptions] = useState(false);
    
    const loader = () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
    
    const getMetaDescription = (product) => {
      if (!product) return '';
      return `${product.name} - ${product.description.substring(0, 155)}...`;
    };
    
    const handleShare = (platform) => {
      const url = window.location.href;
      const title = parentProduct?.name || 'Check out this product';
      const description = parentProduct?.description || 'Product details';
      
      switch(platform) {
        case 'facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
          break;
        case 'twitter':
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`, '_blank');
          break;
        case 'whatsapp':
          window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(title + ': ' + url)}`, '_blank');
          break;
        case 'linkedin':
          window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`, '_blank');
          break;
        default:
          navigator.clipboard.writeText(url).then(() => {
            alert('Link copied to clipboard!');
          });
      }
      setShowShareOptions(false);
    };
    
    const handleGoBack = () => {
      navigate('/');
    };
    
    return(
      <>
        {parentProduct && (
        <Helmet>
          <title>{`${parentProduct.name} | Your Store Name`}</title>
          <meta name="description" content={getMetaDescription(parentProduct)} />
          <meta property="og:title" content={parentProduct.name} />
          <meta property="og:description" content={getMetaDescription(parentProduct)} />
          <meta property="og:image" content={parentProduct.imageUrl} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="product" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={parentProduct.name} />
          <meta name="twitter:description" content={getMetaDescription(parentProduct)} />
          <meta name="twitter:image" content={parentProduct.imageUrl} />
          <link rel="canonical" href={window.location.href} />
          
          {/* Wrap the JSON-LD in a template literal */}
          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${parentProduct.name}",
              "description": "${parentProduct.description}",
              "image": "${parentProduct.imageUrl}",
              "sku": "${parentProduct.skus && parentProduct.skus.length ? parentProduct.skus[0].sku : ''}",
              "brand": {
                "@type": "Brand",
                "name": "Your Brand Name"
              },
              "offers": {
                "@type": "AggregateOffer",
                "priceCurrency": "INR",
                "lowPrice": ${Math.min(...parentProduct.skus.flatMap(sku => 
                  sku.pricing.map(p => p.price)))},
                "highPrice": ${Math.max(...parentProduct.skus.flatMap(sku => 
                  sku.pricing.map(p => p.price)))},
                "availability": "${parentProduct.skus.some(sku => sku.stock > 0) 
                  ? "https://schema.org/InStock" 
                  : "https://schema.org/OutOfStock"}"
              }
            }
          `}</script>
        </Helmet>
      )}
      <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', minHeight: '100vh', mb: {xs: 2, md: 4 } }}>
        {/* Breadcrumb navigation */}
        <Paper sx={{ p: 1, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link 
              underline="hover" 
              color="inherit" 
              href="/" 
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Typography color="text.primary">{parentProduct?.name || 'Product Details'}</Typography>
          </Breadcrumbs>
          
          <Box sx={{ display: 'flex', gap: 1 }}>
           
            
            <Box sx={{ position: 'relative' }}>
              <Tooltip title="Share">
                <IconButton 
                  onClick={() => setShowShareOptions(!showShareOptions)} 
                  sx={{ color: '#33387C' }}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              
              {showShareOptions && (
                <Paper 
                  sx={{ 
                    position: 'absolute', 
                    right: 0, 
                    zIndex: 1000, 
                    p: 1, 
                    display: 'flex', 
                    gap: 1 
                  }}
                >
                  <Tooltip title="Facebook">
                    <IconButton onClick={() => handleShare('facebook')} size="small">
                      <FacebookIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Twitter">
                    <IconButton onClick={() => handleShare('twitter')} size="small">
                      <TwitterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="WhatsApp">
                    <IconButton onClick={() => handleShare('whatsapp')} size="small">
                      <WhatsAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="LinkedIn">
                    <IconButton onClick={() => handleShare('linkedin')} size="small">
                      <LinkedInIcon />
                    </IconButton>
                  </Tooltip>
                </Paper>
              )}
            </Box>
          </Box>
        </Paper>
        
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Paper sx={{ padding: 2, elevation: 0 }}>
              {!loading && imageList && imageList?.img?.length > 0 ? <ImageGallery imagesList={imageList}/>: loader()}
            </Paper>
          </Grid2>

          <Grid2 size={{ xs: 12, md: 8 }}>
            <Paper sx={{ padding: 2, elevation: 0 }}>
              <Typography variant="h5" sx={{ color: "#33387C" }} fontWeight="bold">{parentProduct?.name}</Typography>
              <Typography sx={{ mt: 1, fontSize: '14px' }}>{parentProduct?.description}</Typography>
              <Typography sx={{ mt: 1, fontSize: '14px' }}>GST: {parentProduct?.gst}%</Typography>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, color: "#33387C" }}>
                Select SKUs
              </Typography>
              <Box sx={{ marginTop: '10px'}}>
                <EditableTable
                  rows={skuRows}
                  columns={skuTableColumns?.length ? skuTableColumns : []}
                  setSkuRows={setSkuRows}
                  hideFooter={true}
                  loading={loading}
                  setSelectedQuantities={setSelectedQuantities}
                />
              </Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, color: "#33387C" }}>
                Pricing Information
              </Typography>
              <Box sx={{ marginTop: '10px'}}>
                <EditableTable
                  rows={pricingTableRows}
                  columns={PricingTableColumns}
                  hideFooter={true}
                  loading={loading}
                />
              </Box>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end'}} >
                <Button variant="contained" sx={{ width: {xs: '100%', md: '15%'}, backgroundColor: '#33387C'}} type='submit' onClick={handleAddToCart}>
                  {currentEdit ? 'Update cart': 'Add to cart'}
                </Button>
              </Box>
            </Paper>
          </Grid2>
        </Grid2>
    </Box>
    </>
    )
}

export default ProductDetails;