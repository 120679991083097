import { createTheme } from '@mui/material/styles';

// Create a base theme to access breakpoints in our custom theme
const baseTheme = createTheme();

const theme = createTheme({
    palette: {
        primary: {
          main: '#33387C',
          dark: '#262961', // darker shade for hover states
          light: '#4B4F9A', // lighter shade if needed
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#f50057',
          light: '#ff4081',
          dark: '#c51162',
          contrastText: '#ffffff',
        },
        background: {
          default: '#f5f5f5',
          paper: '#ffffff',
        },
        text: {
          primary: '#333333',
          secondary: '#666666',
        },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 500,
        lineHeight: 1.2,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '2rem',
        },
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: 1.2,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '1.75rem',
        },
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 500,
        lineHeight: 1.2,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '1.5rem',
        },
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: 1.2,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '1.25rem',
        },
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: 1.2,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '1.1rem',
        },
      },
      h6: {
        fontSize: '1.1rem',
        fontWeight: 500,
        lineHeight: 1.2,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '1rem',
        },
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.5,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '0.95rem',
        },
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
        },
      },
      button: {
        textTransform: 'none',
        fontWeight: 500,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '4px',
            padding: '8px 16px',
            [baseTheme.breakpoints.down('sm')]: {
              padding: '10px 16px',
              minHeight: '44px',
            },
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          sizeLarge: {
            padding: '12px 24px',
            [baseTheme.breakpoints.down('sm')]: {
              padding: '14px 24px',
            },
          },
          sizeSmall: {
            padding: '4px 8px',
            [baseTheme.breakpoints.down('sm')]: {
              padding: '6px 10px',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'rgba(51, 56, 124, 0.04)',
            },
            [baseTheme.breakpoints.down('sm')]: {
              padding: '12px',
              '& .MuiSvgIcon-root': {
                fontSize: '1.5rem',
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            [baseTheme.breakpoints.down('sm')]: {
              '& .MuiInputBase-input': {
                padding: '14px 12px',
                fontSize: '1rem',
              },
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            [baseTheme.breakpoints.down('sm')]: {
              padding: '0 16px',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            [baseTheme.breakpoints.down('sm')]: {
              margin: '16px',
              width: 'calc(100% - 32px)',
              maxHeight: 'calc(100% - 32px)',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            [baseTheme.breakpoints.down('sm')]: {
              padding: '12px 8px',
            },
          },
          head: {
            fontWeight: 600,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    spacing: 8,
    shape: {
      borderRadius: 4,
    },
});

export default theme; 