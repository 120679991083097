import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Container, 
  Paper, 
  Grid, 
  Snackbar, 
  Alert 
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useMobile } from '../../styles/responsive';
import { mobileFormField, touchFriendlyButton } from '../../styles/mobileStyles';

const Contact = () => {
  const isMobile = useMobile();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    
    // Show success message
    setSnackbar({
      open: true,
      message: 'Your message has been sent successfully!',
      severity: 'success'
    });
    
    // Reset form
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  // Common field style for all inputs
  const fieldStyle = {
    ...(isMobile ? mobileFormField : { mb: 3 }),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Kaka Store</title>
        <meta name="description" content="Contact Kaka Store for any questions or inquiries. We're here to help!" />
      </Helmet>
      
      <Container 
        maxWidth="md" 
        sx={{ 
          py: { xs: 4, sm: 6, md: 8 },
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Paper 
          elevation={2} 
          sx={{ 
            p: { xs: 3, sm: 4, md: 5 },
            borderRadius: { xs: 2, sm: 3 },
            mb: { xs: 4, sm: 6 }
          }}
        >
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
              mb: { xs: 2, sm: 3 }
            }}
          >
            Contact Us
          </Typography>
          
          <Typography 
            variant="body1" 
            paragraph
            sx={{ mb: { xs: 3, sm: 4 } }}
          >
            Feel free to reach out to us with any questions or inquiries. We're here to help!
          </Typography>
          
          <Box 
            component="form" 
            onSubmit={handleSubmit}
            sx={{ mt: { xs: 2, sm: 3 } }}
          >
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  required
                  sx={fieldStyle}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  required
                  sx={fieldStyle}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  multiline
                  rows={isMobile ? 6 : 8}
                  margin="normal"
                  variant="outlined"
                  required
                  sx={fieldStyle}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  size={isMobile ? "large" : "medium"}
                  type="submit"
                  fullWidth={isMobile}
                  sx={isMobile ? {
                    ...touchFriendlyButton,
                    mt: 2
                  } : {
                    mt: 2,
                    px: 4
                  }}
                >
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        
        <Paper 
          elevation={2} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: { xs: 2, sm: 3 }
          }}
        >
          <Typography 
            variant="h5" 
            component="h2" 
            gutterBottom
            sx={{ 
              fontSize: { xs: '1.5rem', sm: '1.75rem' },
              mb: { xs: 2, sm: 3 }
            }}
          >
            Our Information
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">Address</Typography>
              <Typography variant="body2" paragraph>
                123 Main Street, City Name<br />
                State, Country, Postal Code
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">Contact Details</Typography>
              <Typography variant="body2">
                Email: info@kakastore.com<br />
                Phone: +1 (123) 456-7890<br />
                Hours: Mon-Fri, 9am-5pm
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Contact;
