import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardMedia, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";

// Simple carousel component for product cards
const ProductCardCarousel = ({ images, productName }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  // Process images to ensure they're in the correct format
  const processedImages = React.useMemo(() => {
    if (!images || images.length === 0) return [];
    
    // Handle different possible image data structures
    return images.map(img => {
      // If image is already a string URL
      if (typeof img === 'string') return img;
      
      // If image is an object with img property
      if (img && typeof img === 'object' && img.img) return img.img;
      
      // If image is an object with url property
      if (img && typeof img === 'object' && img.url) return img.url;
      
      // If image is an object with src property
      if (img && typeof img === 'object' && img.src) return img.src;
      
      // If image is an object with image property
      if (img && typeof img === 'object' && img.image) return img.image;
      
      // Fallback
      return '';
    }).filter(url => url); // Remove any empty strings
  }, [images]);
  
  // Function to handle clicking on a dot indicator
  const goToIndex = (e, index) => {
    e.stopPropagation();
    setCurrentIndex(index);
  };
  
  // Auto-scroll effect
  useEffect(() => {
    // Only set up auto-scroll if there are multiple images
    if (!processedImages || processedImages.length <= 1) return;
    
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (
        prevIndex === processedImages.length - 1 ? 0 : prevIndex + 1
      ));
    }, 6000); // Change image every 6 seconds (doubled from 3 seconds)
    
    return () => clearInterval(interval); // Clean up on unmount
  }, [processedImages]);

  // Preload all images
  useEffect(() => {
    if (processedImages && processedImages.length > 0) {
      processedImages.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    }
  }, [processedImages]);

  // If no images or only one image, just display it
  if (!processedImages || processedImages.length <= 1) {
    const imageUrl = processedImages?.[0] || '';
    return (
      <CardMedia
        component="img"
        image={imageUrl}
        alt={productName}
        sx={{
          height: {
            xs: "60%",
            sm: "65%",
          },
          objectFit: 'contain',
          backgroundColor: '#f5f5f5'
        }}
      />
    );
  }

  return (
    <Box sx={{ 
      position: 'relative',
      height: {
        xs: "60%",
        sm: "65%",
      },
      backgroundColor: '#f5f5f5'
    }}>
      {/* Main carousel display */}
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {processedImages.map((imageUrl, index) => (
          <Box
            key={index}
            sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: index === currentIndex ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'opacity 0.5s ease-in-out',
              opacity: index === currentIndex ? 1 : 0,
            }}
          >
            <img
              src={imageUrl}
              alt={`${productName} - ${index + 1}`}
              style={{ 
                maxHeight: '100%', 
                maxWidth: '100%', 
                objectFit: 'contain'
              }}
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/150?text=Image+Not+Found';
              }}
            />
          </Box>
        ))}
      </Box>
      
      {/* Image indicator dots */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 5,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: '5px',
        }}
      >
        {processedImages.map((_, index) => (
          <Box
            key={index}
            onClick={(e) => goToIndex(e, index)}
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: index === currentIndex ? '#33387C' : 'rgba(0, 0, 0, 0.3)',
              transition: 'background-color 0.3s ease',
              cursor: 'pointer',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

const ProductCard = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchProducts = async () => {
        setLoading(true);
        try {
          const productsSnapshot = await getDocs(collection(db, "parent_products"));
          const productList = productsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProducts(productList);
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchProducts();
    }, []);

  const navigate = useNavigate();

  const handleCardClick = (product) => {
    const urlPath = product.slug || product.id;
    navigate(`/product/${urlPath}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: 'calc(100vh - 200px)', }}>
        <Box
        sx={{
          textAlign: "center",
          mt: {
            xs: 2,
            sm: 4
        },
          color: "#33387C",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            mb: {
                xs: 1,
                sm: 2
            },
            textTransform: "uppercase",
            letterSpacing: 2,
            fontSize: {
                xs: "1.25rem", // Smaller font size for mobile devices
                sm: "2rem",   // Slightly larger for tablets
                md: "2.5rem", // Default font size for larger screens
            },
          }}
        >
          Explore Our Products
        </Typography>
      </Box>
      <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: {
          xs: "repeat(2, 1fr)", // 2 cards per row on mobile
          sm: "repeat(3, 1fr)", // 3 cards per row on tablets
          md: "repeat(4, 1fr)", // 4 cards per row on desktop
        },
        marginTop: "15px",
        padding: "10px"
      }}
    >
      {products.map((product) => (
        <Card
          key={product.id}
          onClick={() => handleCardClick(product)}
          sx={{
            cursor: "pointer",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
            },
            height: {
              xs: "320px",
              sm: "350px",
            },
          }}
        >
          <ProductCardCarousel 
            images={product.images || []} 
            productName={product.name} 
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {product.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            {product.description.length > 60 
                ? `${product.description.substring(0, 60)}...` 
                : product?.description || ''}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
    </Box>
  );
};

export default ProductCard;
