import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * Custom hook to check if the current viewport is mobile
 * @returns {boolean} True if the viewport is mobile (< 600px)
 */
export const useMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

/**
 * Custom hook to check if the current viewport is tablet
 * @returns {boolean} True if the viewport is tablet (>= 600px and < 960px)
 */
export const useTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('sm', 'md'));
};

/**
 * Custom hook to check if the current viewport is desktop
 * @returns {boolean} True if the viewport is desktop (>= 960px)
 */
export const useDesktop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('md'));
};

/**
 * Responsive spacing utility
 * Returns different spacing values based on the current viewport
 * @param {Object} options - Spacing options
 * @param {number|string} options.xs - Spacing for mobile
 * @param {number|string} options.sm - Spacing for tablet
 * @param {number|string} options.md - Spacing for desktop
 * @param {number|string} options.lg - Spacing for large desktop
 * @param {number|string} options.xl - Spacing for extra large desktop
 * @returns {Object} MUI sx prop object with responsive spacing
 */
export const responsiveSpacing = (property, options) => {
  const { xs, sm, md, lg, xl } = options;
  
  return {
    [property]: {
      xs: xs,
      sm: sm || xs,
      md: md || sm || xs,
      lg: lg || md || sm || xs,
      xl: xl || lg || md || sm || xs,
    },
  };
};

/**
 * Responsive font size utility
 * @param {Object} options - Font size options
 * @param {number|string} options.xs - Font size for mobile
 * @param {number|string} options.sm - Font size for tablet
 * @param {number|string} options.md - Font size for desktop
 * @param {number|string} options.lg - Font size for large desktop
 * @param {number|string} options.xl - Font size for extra large desktop
 * @returns {Object} MUI sx prop object with responsive font size
 */
export const responsiveFontSize = (options) => {
  return responsiveSpacing('fontSize', options);
};

/**
 * Responsive padding utility
 * @param {Object} options - Padding options
 * @returns {Object} MUI sx prop object with responsive padding
 */
export const responsivePadding = (options) => {
  return responsiveSpacing('padding', options);
};

/**
 * Responsive margin utility
 * @param {Object} options - Margin options
 * @returns {Object} MUI sx prop object with responsive margin
 */
export const responsiveMargin = (options) => {
  return responsiveSpacing('margin', options);
};

/**
 * Responsive width utility
 * @param {Object} options - Width options
 * @returns {Object} MUI sx prop object with responsive width
 */
export const responsiveWidth = (options) => {
  return responsiveSpacing('width', options);
};

/**
 * Responsive height utility
 * @param {Object} options - Height options
 * @returns {Object} MUI sx prop object with responsive height
 */
export const responsiveHeight = (options) => {
  return responsiveSpacing('height', options);
};

/**
 * Responsive display utility
 * @param {Object} options - Display options
 * @returns {Object} MUI sx prop object with responsive display
 */
export const responsiveDisplay = (options) => {
  return responsiveSpacing('display', options);
};

/**
 * Common responsive styles for containers
 * @returns {Object} MUI sx prop object with responsive container styles
 */
export const responsiveContainer = {
  width: '100%',
  px: { xs: 2, sm: 3, md: 4 },
  mx: 'auto',
};

/**
 * Common responsive styles for cards
 * @returns {Object} MUI sx prop object with responsive card styles
 */
export const responsiveCard = {
  p: { xs: 2, sm: 3, md: 4 },
  borderRadius: { xs: 2, sm: 3 },
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
};

/**
 * Common responsive styles for buttons
 * @returns {Object} MUI sx prop object with responsive button styles
 */
export const responsiveButton = {
  minHeight: { xs: '44px', sm: '40px' },
  px: { xs: 3, sm: 4 },
  py: { xs: 1.5, sm: 1 },
};

/**
 * Common responsive styles for form fields
 * @returns {Object} MUI sx prop object with responsive form field styles
 */
export const responsiveFormField = {
  mb: { xs: 3, sm: 2 },
};

/**
 * Common responsive styles for section spacing
 * @returns {Object} MUI sx prop object with responsive section spacing
 */
export const responsiveSection = {
  py: { xs: 4, sm: 6, md: 8 },
  mb: { xs: 4, sm: 6, md: 8 },
}; 