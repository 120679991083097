import { breakpoints } from './breakpoints';

/**
 * Mobile-specific styles for common UI patterns
 */

// Touch-friendly button styles
export const touchFriendlyButton = {
  minHeight: '44px',
  minWidth: '44px',
  padding: '12px 16px',
  borderRadius: '8px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  touchAction: 'manipulation',
};

// Mobile card styles with proper spacing
export const mobileCard = {
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  marginBottom: '16px',
  width: '100%',
};

// Mobile-friendly form field styles
export const mobileFormField = {
  marginBottom: '20px',
  '& .MuiInputBase-input': {
    fontSize: '16px',
    padding: '14px 12px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px',
  },
};

// Mobile-friendly table card (alternative to tables on mobile)
export const mobileTableCard = {
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '12px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};

// Mobile-friendly table row (for card-based tables)
export const mobileTableRow = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0',
  borderBottom: '1px solid #eee',
  '&:last-child': {
    borderBottom: 'none',
  },
};

// Mobile-friendly table cell label
export const mobileTableCellLabel = {
  fontWeight: 600,
  fontSize: '14px',
  color: '#666',
};

// Mobile-friendly table cell value
export const mobileTableCellValue = {
  fontSize: '14px',
  textAlign: 'right',
};

// Mobile-friendly bottom navigation
export const mobileBottomNav = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: '#fff',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '8px 0',
  height: '64px',
};

// Mobile-friendly bottom navigation item
export const mobileBottomNavItem = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  minWidth: '60px',
  color: '#666',
  textDecoration: 'none',
  fontSize: '12px',
  '& svg': {
    fontSize: '24px',
    marginBottom: '4px',
  },
};

// Mobile-friendly drawer menu
export const mobileDrawerMenu = {
  width: '85%',
  maxWidth: '320px',
  height: '100%',
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
};

// Mobile-friendly drawer menu item
export const mobileDrawerMenuItem = {
  padding: '16px',
  borderBottom: '1px solid #eee',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  '& svg': {
    fontSize: '20px',
    color: '#666',
  },
};

// Mobile-friendly section spacing
export const mobileSectionSpacing = {
  marginBottom: '32px',
  paddingTop: '24px',
  paddingBottom: '24px',
};

// Mobile-friendly heading styles
export const mobileHeading = {
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '16px',
  lineHeight: 1.3,
};

// Mobile-friendly subheading styles
export const mobileSubheading = {
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '12px',
  lineHeight: 1.3,
};

// Mobile-friendly grid container
export const mobileGridContainer = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '12px',
};

// Mobile-friendly list item
export const mobileListItem = {
  padding: '12px 0',
  borderBottom: '1px solid #eee',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

// Mobile-friendly image container with proper aspect ratio
export const mobileImageContainer = {
  width: '100%',
  position: 'relative',
  paddingBottom: '75%', // 4:3 aspect ratio
  overflow: 'hidden',
  borderRadius: '8px',
  marginBottom: '16px',
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

// Mobile-friendly modal
export const mobileModal = {
  margin: '16px',
  width: 'calc(100% - 32px)',
  maxHeight: 'calc(100% - 32px)',
  borderRadius: '12px',
  padding: 0,
  overflow: 'hidden',
};

// Mobile-friendly modal header
export const mobileModalHeader = {
  padding: '16px',
  borderBottom: '1px solid #eee',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

// Mobile-friendly modal content
export const mobileModalContent = {
  padding: '16px',
  maxHeight: 'calc(100vh - 180px)',
  overflowY: 'auto',
};

// Mobile-friendly modal footer
export const mobileModalFooter = {
  padding: '16px',
  borderTop: '1px solid #eee',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
};

// Media query function for mobile-only styles
export const forMobile = (styles) => {
  return {
    [`@media (max-width: ${breakpoints.sm - 1}px)`]: styles,
  };
};

// Media query function for non-mobile styles
export const forDesktop = (styles) => {
  return {
    [`@media (min-width: ${breakpoints.sm}px)`]: styles,
  };
}; 